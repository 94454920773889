import React from 'react'
import find from 'lodash/find'
import { Form, Row, Col, Input, Switch, Select, Button, message, Modal, Tooltip } from 'antd'
import { TranslationOutlined, EditOutlined } from '@ant-design/icons'
import MemoryStore from '../../../utils/memory-store'
import GroupNameContainer from '../../../reusables/containers/group-name-container'
import SolutionsContainer from '../../../reusables/containers/solutions-container'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import ModuleIcon from '../../../reusables/components/module-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'
import Description from '../../../reusables/components/description'
import CompileTemplateIcon from '../../../reusables/components/compile-template-icon'
import SingleValueTableEditableDND from '../../../reusables/components/single-value-table-editable-dnd'
import Notes from '../../../reusables/components/notes'
import Enums from '../../../utils/enums'
import LanguageTranslation from '../../../reusables/components/language-translation'
import { getConnectorProfiles, getNumberingProfiles, getRoleProfiles } from '../../bpm-utils'
import agiliteTheme from '../../../utils/agilite-theme'

class BPMFormGeneral extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]
    this.roleProfileTemplate = MemoryStore.roles.dataTemplate
    this.tmpRoleProfile = JSON.parse(JSON.stringify(this.roleProfileTemplate))

    this.iln = this.entry.data.iln ? this.entry.data.iln : {}

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      roleProfilesElements: [],
      roleProfilesData: [],
      appAdmin: this.entry.data.appAdmin,
      numberingProfileElements: [],
      numberingProfileData: [],
      numberingProfile: this.entry.data.numberingId ? this.entry.data.numberingId : '',
      prevNumberingProfile: this.entry.data.numberingId ? this.entry.data.numberingId : '',
      modal: {
        visible: false
      },
      languageModalVisible: false,
      dataKey: null,
      key: this.props.profileKey,
      dataSourceData: []
    }

    this.handleGetConnectorProfiles = this.handleGetConnectorProfiles.bind(this)
    this.handleGetRoleProfiles = this.handleGetRoleProfiles.bind(this)
    this.handleGetNumberName = this.handleGetNumberName.bind(this)
    this.handleGetNumberingProfiles = this.handleGetNumberingProfiles.bind(this)
    this.handleSaveRole = this.handleSaveRole.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleOnRoleLevelChange = this.handleOnRoleLevelChange.bind(this)
    this.updateRoleLevels = this.updateRoleLevels.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleCloseModalConfirm = this.handleCloseModalConfirm.bind(this)
    this.handleDataChange = this.handleDataChange.bind(this)
    this.handleDataSubmit = this.handleDataSubmit.bind(this)
  }

  async UNSAFE_componentWillMount() {
    if (MemoryStore.userProfile.teamPrivileges.bpm !== 'Reader') {
      await this.handleGetRoleProfiles()
      await this.handleGetNumberingProfiles()
      await this.handleGetConnectorProfiles()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profileKey !== this.state.key) this.setState({ key: nextProps.profileKey })
  }

  handleGetConnectorProfiles() {
    const tmpThis = this
    let result = null

    return new Promise((resolve, reject) => {
      ;(async () => {
        try {
          result = await getConnectorProfiles()

          const profiles = result.data
            .filter((profile) => profile.data.connectionType === '10')
            .map((profile) => ({
              label: `${profile.data.name} (${profile.data.key})`,
              value: profile.data.key
            }))
            .sort((a, b) => a.label.localeCompare(b.label))

          tmpThis.setState({
            dataSourceData: profiles
          })
          resolve()
        } catch (error) {
          message.error(error)
          reject(error)
        }
      })()
    })
  }

  handleGetRoleProfiles() {
    const tmpThis = this
    let tmpProfiles = []
    let profiles = []
    let result = null

    return new Promise((resolve, reject) => {
      ;(async () => {
        try {
          result = await getRoleProfiles()

          result.data.map((profile) => {
            return tmpProfiles.push(profile.data.name)
          })

          profiles = [...new Set(tmpProfiles)]
          tmpProfiles = []

          profiles = profiles.sort()

          profiles.map((profile) => {
            return tmpProfiles.push(<Select.Option key={profile}>{profile}</Select.Option>)
          })

          tmpThis.setState({
            roleProfilesElements: tmpProfiles,
            roleProfilesData: profiles
          })
          resolve()
        } catch (error) {
          message.error(error)
          reject(error)
        }
      })()
    })
  }

  handleGetNumberingProfiles() {
    const tmpThis = this
    let tmpProfiles = []
    let profiles = []
    let result = null

    return new Promise((resolve, reject) => {
      ;(async () => {
        try {
          result = await getNumberingProfiles()

          result.data.map((profile) => {
            return tmpProfiles.push(profile)
          })

          profiles = [...new Set(tmpProfiles)]
          tmpProfiles = []

          profiles = profiles.sort()

          profiles.map((profile) => {
            return tmpProfiles.push(
              <Select.Option key={profile._id} value={profile._id}>
                {profile.data.name}
              </Select.Option>
            )
          })

          tmpThis.setState({
            numberingProfileElements: tmpProfiles,
            numberingProfileData: profiles
          })
          resolve()
        } catch (error) {
          message.error(error)
          reject(error)
        }
      })()
    })
  }

  async handleSaveRole() {
    const tmpThis = this

    // Validate
    if (!this.tmpRoleProfile.data.name) return message.error('Please provide a Role Name')
    if (find(this.state.roleProfilesData, (e) => e.toLowerCase() === this.tmpRoleProfile.data.name.toLowerCase()))
      return message.error('Role Name already exists')
    if (!this.tmpRoleProfile.data.tmpResponsibleUser) return message.error('Please provide a Responsible User')

    this.tmpRoleProfile.data.responsibleUser = this.tmpRoleProfile.data.tmpResponsibleUser.split(',')

    try {
      await tmpThis.props.onSaveRoleProfile(tmpThis.tmpRoleProfile)
      await tmpThis.handleGetRoleProfiles()

      tmpThis.entry.data.appAdmin = tmpThis.tmpRoleProfile.data.name
      tmpThis.setState({
        appAdmin: tmpThis.tmpRoleProfile.data.name,
        modal: { visible: false }
      })

      message.success('Role Profile Successfully created')
    } catch (error) {
      message.error(error)
    }
  }

  onChange(key, value) {
    this.entry.custom.isModifiedSubform = true
    this.tmpRoleProfile.data[key] = value
  }

  updateRoleLevels(data) {
    this.entry.custom.isModifiedSubform = true
    this.tmpRoleProfile.data.levels = data
  }

  handleOnRoleLevelChange(key, value, index) {
    this.entry.custom.isModifiedSubform = true
    this.tmpRoleProfile.data[key][index] = { value }
  }

  handleCloseModal() {
    this.tmpRoleProfile = JSON.parse(JSON.stringify(this.roleProfileTemplate))
    this.setState({ modal: { visible: false } })
  }

  handleCloseModalConfirm() {
    if (this.entry.custom.isModifiedSubform) {
      Modal.confirm({
        onOk: () => this.handleCloseModal(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.handleCloseModal()
    }
  }

  handleDataChange(data) {
    this.iln[this.state.dataKey] = data
  }

  handleDataSubmit() {
    this.setState({ languageModalVisible: false })
    this.props.onChange('iln', this.iln)
  }

  handleGetNumberName(recordId) {
    let tmpIndex = -1

    tmpIndex = this.state.numberingProfileData.findIndex((profile) => profile._id === recordId)

    if (tmpIndex > -1) {
      return this.state.numberingProfileData[tmpIndex].data.name
    } else {
      return ''
    }
  }

  render() {
    return (
      <Row type='flex' justify='space-between'>
        <Col xs={24} lg={11}>
          <Form.Item>
            <Switch
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              defaultChecked={this.entry.data.isActive}
              onChange={(e) => {
                this.props.onChange('isActive', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Profile Key '}
            <TooltipIcon title={EnumsTooltips.general.profileKey} />
            <Input
              name='key'
              placeholder='Provide a unique Profile Key'
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader' || !this.state.isNewDoc}
              value={this.state.key}
              required
              onChange={(e) => {
                this.props.onChange('key', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Profile Name '}
            <TooltipIcon title={EnumsTooltips.general.profileName} />
            <Tooltip title='Add language alternatives for field value'>
              <TranslationOutlined
                onClick={() => this.setState({ languageModalVisible: true, dataKey: 'name' })}
                style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
              />
            </Tooltip>
            <Input
              name='name'
              placeholder='Provide a Profile Name'
              required
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              defaultValue={this.entry.data.name}
              onChange={(e) => {
                this.props.onChange('name', e.target.value)
                this.entry.data.iln.name = {
                  ...this.entry.data.iln.name,
                  [MemoryStore.defaultLanguage]: e.target.value
                }
              }}
            />
          </Form.Item>
          <Description
            disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            description={this.entry.data.description}
            onChange={(e) => {
              this.props.onChange('description', e.target.value)
              this.entry.data.iln.description = {
                ...this.entry.data.iln.description,
                [MemoryStore.defaultLanguage]: e.target.value
              }
            }}
            icon={
              <Tooltip title='Add language alternatives for field value'>
                <TranslationOutlined
                  onClick={() => this.setState({ languageModalVisible: true, dataKey: 'description' })}
                  style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
                />
              </Tooltip>
            }
          />
          <GroupNameContainer
            disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            defaultValue={this.entry.data.groupName}
            onValueChange={(value) => {
              this.props.onChange('groupName', value)
            }}
            onValueSelect={(value) => {
              this.props.onChange('groupName', value)
            }}
          />
          <SolutionsContainer
            disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            defaultValue={this.entry.data.solutions}
            onValueChange={(value) => {
              this.props.onChange('solutions', value)
            }}
          />
          <Form.Item>
            Data Source (MongoDB Connector) (optional)
            <Select
              name='dataSource'
              placeholder='Provide a Data Source'
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              defaultValue={this.entry.data.dataSource}
              required
              onChange={(value) => {
                this.props.onChange('dataSource', value)
              }}
            >
              {this.state.dataSourceData.map((profile) => (
                <Select.Option key={profile.value} value={profile.value}>
                  {profile.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item style={{ marginTop: 40 }}>
            <span style={{ color: 'red' }}>* </span>
            Locked Expiry (minutes)
            <Input
              name='lockedExpiry'
              placeholder='Provide a Locked Expiry in minutes'
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              defaultValue={this.entry.data.lockedExpiry}
              required
              onChange={(e) => {
                this.props.onChange('lockedExpiry', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <ModuleIcon module='ROLES' />
            {' App Admin Role (optional) '}
            <TooltipIcon title={EnumsTooltips.bpm.appAdmin} />
            <Select
              placeholder='Application Administrator Role'
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              value={this.state.appAdmin}
              style={{ width: '85%' }}
              onChange={(value) => {
                this.props.onChange('appAdmin', value)
                this.setState({ appAdmin: value })
              }}
            >
              <Select.Option value=''>-None-</Select.Option>
              {this.state.roleProfilesElements}
              <Select.Option value='add_new'>-Add New-</Select.Option>
            </Select>
            {this.state.appAdmin !== 'add_new' ? null : (
              <Button
                type='default'
                style={{ marginLeft: 10 }}
                onClick={() => {
                  this.setState({ modal: { visible: true } })
                  this.tmpRoleProfile = JSON.parse(JSON.stringify(this.roleProfileTemplate))
                }}
              >
                <EditOutlined />
              </Button>
            )}
          </Form.Item>
          <Form.Item>
            Numbering Profile
            <Select
              onChange={(value) => {
                this.props.onChange('numberingId', value)
                this.setState({ numberingProfile: value })
              }}
              value={this.state.numberingProfile}
            >
              {this.entry.custom.isNewDoc ? <Select.Option value=''>Auto Create (default)</Select.Option> : null}
              {this.state.numberingProfileElements}
            </Select>
            {this.state.prevNumberingProfile && this.state.numberingProfile !== this.state.prevNumberingProfile ? (
              <span style={{ color: agiliteTheme.dangerColor }}>
                Previous Numbering Profile: <b>{this.handleGetNumberName(this.state.prevNumberingProfile)}</b>
              </span>
            ) : null}
          </Form.Item>
          <Form.Item>
            {'App URL (optional) '}
            <TooltipIcon title={EnumsTooltips.bpm.appUrl} />
            <Input
              name='app_url'
              placeholder='URL endpoint for the target application'
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              defaultValue={this.entry.data.appUrl}
              onChange={(e) => {
                this.props.onChange('appUrl', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Reference URL (optional) '}
            <TooltipIcon title={EnumsTooltips.bpm.referenceUrl} />
            <Input
              name='ref_url'
              placeholder='Reference URL for videos, resources, etc.'
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              defaultValue={this.entry.data.referenceUrl}
              onChange={(e) => {
                this.props.onChange('referenceUrl', e.target.value)
              }}
            />
          </Form.Item>
          <Notes
            disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            notes={this.entry.data.notes}
            onChange={(e) => {
              this.props.onChange('notes', e.target.value)
              this.entry.data.iln.notes = {
                ...this.entry.data.iln.notes,
                [MemoryStore.defaultLanguage]: e.target.value
              }
            }}
            icon={
              <Tooltip title='Add language alternatives for field value'>
                <TranslationOutlined
                  onClick={() => this.setState({ languageModalVisible: true, dataKey: 'notes' })}
                  style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
                />
              </Tooltip>
            }
          />
        </Col>
        <Modal
          visible={this.state.modal.visible}
          title={Enums.APP_PROFILE_TITLES.roles}
          closable={false}
          okText='Ok'
          okButtonProps={{
            style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' }
          }}
          onCancel={this.handleCloseModalConfirm}
          onOk={this.handleSaveRole}
          width={1050}
          style={{ top: 10 }}
          destroyOnClose
        >
          <Form autoComplete='off'>
            <Row gutter={32} type='flex' justify='space-around'>
              <Col xs={24} sm={16} md={8} lg={8}>
                <Form.Item>
                  <Switch
                    checkedChildren='Active'
                    unCheckedChildren='Inactive'
                    defaultChecked={this.tmpRoleProfile.data.isActive}
                    onChange={(e) => {
                      this.onChange('isActive', e)
                    }}
                  />
                </Form.Item>
                <Form.Item required>
                  <span style={{ color: 'red' }}>* </span>Role Name
                  <Input
                    placeholder='Provide a Role Name'
                    defaultValue={this.tmpRoleProfile.data.name}
                    required
                    onChange={(e) => {
                      this.onChange('name', e.target.value)
                    }}
                  />
                </Form.Item>
                <Description
                  disabled={false}
                  description={this.tmpRoleProfile.data.description}
                  onChange={(e) => {
                    this.onChange('description', e.target.value)
                  }}
                />
                <GroupNameContainer
                  defaultValue={this.tmpRoleProfile.data.groupName}
                  onValueSelect={(value) => {
                    this.onChange('groupName', value)
                  }}
                  onValueChange={(value) => {
                    this.onChange('groupName', value)
                  }}
                />
              </Col>
              <Col xs={24} sm={16} md={12} lg={14} style={{ marginTop: 50 }}>
                <Form.Item required>
                  <span style={{ color: 'red' }}>* </span>
                  {'Responsible User '}
                  <CompileTemplateIcon />
                  <Input
                    placeholder='This can be a Name, Email, ID, etc.'
                    defaultValue={this.tmpRoleProfile.data.responsibleUser}
                    required
                    onChange={(e) => {
                      this.onChange('tmpResponsibleUser', e.target.value)
                    }}
                  />
                </Form.Item>
                <div>
                  <SingleValueTableEditableDND
                    data={this.tmpRoleProfile.data.levels}
                    disabled={false}
                    title='Conditional Levels (optional) {{}}'
                    theme={this.props.theme}
                    callback={this.updateRoleLevels}
                    arrayKey='levels'
                    onChange={this.handleOnRoleLevelChange}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          title='Language Translate'
          visible={this.state.languageModalVisible}
          onOk={this.handleDataSubmit}
          onCancel={() => this.setState({ languageModalVisible: false })}
          closable={false}
          width='50%'
          destroyOnClose
          okButtonProps={{
            style: {
              backgroundColor: '#67AD5B',
              color: 'white'
            }
          }}
          okText='Submit'
        >
          <LanguageTranslation
            theme={this.props.theme}
            disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            data={this.iln[this.state.dataKey]}
            dataKey={this.state.dataKey}
            defaultLanguage={MemoryStore.defaultLanguage}
            privileges={MemoryStore.userProfile.teamPrivileges.bpm}
            fieldValue={this.entry.data[this.state.dataKey]}
            onDataChange={this.handleDataChange}
          />
        </Modal>
      </Row>
    )
  }
}

export default BPMFormGeneral
